import {web3} from '../connection/web3';

export const weiPrice = (price: number) => {
  // @ts-ignore
  return web3[0].utils.toWei(price.toString(), 'ether');
}
export const ethPrice = (price: string) => {
  // @ts-ignore
  return web3[0].utils.fromWei(price, 'ether');
}
