import { useRecoilValue } from 'recoil';
import walletAccountAtom from '../../../atoms/walletAccount';
import { getShortAddress } from '../../../utils';

function SecName() {
  const walletAccount = useRecoilValue(walletAccountAtom);

  return (
    <div className="col-auto my-auto">
      <div className="h-100 d-flex">
        <h5 className="mb-1 me-3">My wallet address</h5>
        <h5 className="mb-1">{getShortAddress(walletAccount.account)}</h5>
        {/* <h5 className="mb-1">Alec Thompson</h5> */}
        {/* <p className="mb-0 font-weight-bold text-sm">CEO / Co-Founder</p> */}
      </div>
    </div>
  );
}

export default SecName;
