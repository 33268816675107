import Input from './Input';
import { create } from 'ipfs-http-client';
import { useDropzone } from 'react-dropzone';
import React from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { useWeb3React } from '@web3-react/core';
import { useRecoilValue, useRecoilState } from 'recoil';
// import walletAccountAtom from '../../../atoms/walletAccount';
import Textarea from './Textarea';
import NFTCollection from '../../../abis/NFT.json';
import contracts, { contractWallets } from '../../../constants/contracts';
import { utils } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { web3 } from '../../../connection/web3';
import { AbiItem } from 'web3-utils';
import createItemCategoryAtom from '../../../atoms/createItemCategory';
import swal from 'sweetalert';
import {
  networkId,
  networks,
  symbolChk,
} from '../../../constants/networkChain';
import { addresses } from '../../../constants/addresses';
// @ts-ignore
import { prepare, request, getResult } from 'klip-sdk';
import walletAccountAtom from '../../../atoms/walletAccount';
import { isMobile } from 'react-device-detect';
import klipRequestKeyAtom from '../../../atoms/klipRequestKey';
import { useInterval } from 'usehooks-ts';
import { getNetworkFromChainId } from '../../../utils';

const ipfs = create({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
});

const CardForm = ({ data }: { data: any }) => {
  // const walletAccount = useRecoilValue(walletAccountAtom);
  const { account } = useWeb3React();
  const [capturedFileBuffer, setCapturedFileBuffer] =
    React.useState<Buffer | null>(null);
  const [title, setTitle] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  // const [artist, setArtist] = React.useState<string>('');
  // const [twitter, setTwitter] = React.useState<string>('');
  // const [instagram, setInstagram] = React.useState<string>('');
  // const [blog, setBlog] = React.useState<string>('');
  // const [site, setSite] = React.useState<string>('');
  const [network, setNetwork] = React.useState<string>('');
  const [ipfsImage, setIpfsImage] = React.useState<string>('');
  const [agree, setAgree] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const createItemCategory = useRecoilValue(createItemCategoryAtom);
  const walletAccount = useRecoilValue(walletAccountAtom);
  const [klipRequestKey, setKlipRequestKey] =
    useRecoilState(klipRequestKeyAtom);

  const onDrop = React.useCallback((files: any) => {
    setBuffer(files[0]);
  }, []);
  const symbol = symbolChk(network);
  const nId = networkId(network);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const captureFile: React.ChangeEventHandler<HTMLInputElement> = (
    event: any
  ) => {
    event.preventDefault();

    const file = event.target.files[0];

    setBuffer(file);
  };

  const setBuffer = (file: any) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => {
      // @ts-ignore
      setCapturedFileBuffer(Buffer(reader.result));
    };
  };

  useInterval(
    () => {
      getResult(klipRequestKey).then(
        (result: {
          expiration_time: number;
          request_key: string;
          result: { klaytn_address: string };
          klaytn_address: string;
          status: string;
        }) => {
          if (result.status === 'completed') {
            reset();
            setKlipRequestKey('');
          }
        }
      );
    },
    isLoading && klipRequestKey ? 1000 : null
  );

  const klipMint = async (metadataAdded: any) => {
    const res = await prepare.executeContract({
      bappName: 'MINTAINER',
      from: walletAccount.account,
      to: addresses(2).nft,
      value: '0',
      abi: JSON.stringify({
        inputs: [
          {
            internalType: 'string',
            name: 'tokenURI',
            type: 'string',
          },
        ],
        name: 'mintNFT',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      }),
      params: JSON.stringify([metadataAdded.path]),
    });

    if (res.request_key) {
      if (isMobile) {
        request(res.request_key);
      }
      setKlipRequestKey(res.request_key);
    }
  };

  const onSubmit = async () => {
    if (getNetworkFromChainId(walletAccount.chainId) !== network) {
      swal('Oops! Please check the network!');
      return;
    }
    if (!capturedFileBuffer) {
      swal('File is required!');
      return;
    }
    if (!title) {
      swal('Item Title is required!');
      return;
    }
    if (!description) {
      swal('Item Description is required!');
      return;
    }

    if (!network) {
      swal('network is required!');
      return;
    }

    setIsLoading(true);

    const fileAdded = await ipfs.add(capturedFileBuffer);

    const metadata = {
      title: 'Asset Metadata',
      type: 'object',
      properties: {
        name: {
          type: 'string',
          description: title,
        },
        description: {
          type: 'string',
          description: description,
        },
        image: {
          type: 'string',
          description: `https://ipfs.infura.io/ipfs/${fileAdded.path}`,
        },
        category: {
          type: 'number',
          description: createItemCategory,
        },
        network: {
          type: 'string',
          description: network,
        },
      },
    };

    const metadataAdded = await ipfs.add(JSON.stringify(metadata));
    if (!metadataAdded) {
      console.error('Something went wrong when updloading the file');
      return;
    }

    console.log('metadataAdded');
    console.log(metadataAdded);

    if (walletAccount.wallet === 'klip') {
      await klipMint(metadataAdded);
      return;
    } else {
      try {
        swal('Please wait...', 'Requesting to create NFT', {
          timer: 60000,
        });
        const result = await contractWallets(nId)
          .nftContract.methods.mintNFT(metadataAdded.path)
          .send({ from: walletAccount.account, gas: '300000' });
        console.log(result);
      } catch (e) {
        console.log(e);
        swal(
          `Transaction request failed`,
          `Please request again or check the network.`,
          `error`
        );
        setIsLoading(false);
        return;
      }
    }

    reset();
  };
  const onChangeDropDown = (event: any, data: any) => {
    setNetwork(data.value);
  };

  const reset = () => {
    setCapturedFileBuffer(null);
    setTitle('');
    setDescription('');
    setAgree(false);
    setIsLoading(false);
    swal(
      'Your NFT is successfully created.',
      'Now try trading more NFTs',
      'success'
    );
  };

  return (
    <div className="card-body">
      <form>
        {/* <div className="mt-2 position-relative">
          <p className="text-l font-weight-bold mb-15 text-secondary text-border d-inline z-index-2 bg-white">
            Upload File
          </p>
        </div> */}
        <div className="upload-div" {...getRootProps()}>
          {capturedFileBuffer ? (
            <img
              src={`data:image/png;base64,${capturedFileBuffer.toString(
                'base64'
              )}`}
              style={{ maxWidth: '100%' }}
            />
          ) : (
            <div className="mt-2 position-relative">
              <p className="text-sm font-weight-bold mb-15 text-secondary text-border pre-wrap z-index-2 bg-white">
                Image, Video, Audio
              </p>
              <p className="text-sm font-weight-bold mb-15 text-secondary text-border pre-wrap z-index-2 bg-white">
                File Type: JPG, PNG, GIF, SVG, MP4...etc
              </p>
              <button
                className="btn btn-outline-dark btn-sm mb-0"
                style={{ textTransform: 'lowercase' }}
              >
                {isDragActive ? 'Drop the files here ...' : 'Choose File'}
              </button>
            </div>
          )}
          <input
            type="file"
            name="upload"
            id="upload-btn"
            required
            onChange={captureFile}
            {...getInputProps()}
          />
        </div>
        {/* <p className="text-l font-weight-bold mb-15 text-secondary text-border d-inline z-index-2 bg-white">
          Title
        </p> */}
        <Input
          placeholder="Item Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {/* <p className="text-l font-weight-bold mb-15 text-secondary text-border d-inline z-index-2 bg-white">
          Description
        </p> */}
        <Textarea
          placeholder="Item Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {/* <p className="text-l font-weight-bold mb-15 text-secondary text-border d-inline z-index-2 bg-white">
        Artist
        </p>
        <Input
          placeholder="Artist name"
          value={artist}
          onChange={(e) => setArtist(e.target.value)}
        />
        <p className="text-l font-weight-bold mb-15 text-secondary text-border d-inline z-index-2 bg-white">
        SNS
        </p>
        <Input
          placeholder="twitter"
          value={twitter}
          onChange={(e) => setTwitter(e.target.value)}
        />
        <Input
          placeholder="instagram"
          value={instagram}
          onChange={(e) => setInstagram(e.target.value)}
        />
        <Input
          placeholder="blog"
          value={blog}
          onChange={(e) => setBlog(e.target.value)}
        />
        <Input
          placeholder="your site"
          value={site}
          onChange={(e) => setSite(e.target.value)}
        /> */}
        <Menu>
          <Dropdown
            item
            placeholder="Choose network"
            bold
            direction="right"
            fluid
            selection
            options={networks}
            onChange={onChangeDropDown}
          />
        </Menu>
        {/* <p className="text-l font-weight-bold mb-15 text-secondary text-border d-inline z-index-2 bg-white">
        Royalty
        </p>
        <Input  
        type="number" 
        placeholder="0%, 10%, 20%, 30%. Maximum is 50%"
        value={royalty} 
        onChange={onChangeRoyalty}
        />
        <p className="text-l font-weight-bold mb-15 text-secondary text-border d-inline z-index-2 bg-white">
        Royalty Suggested: 0%, 10%, 20%, 30%. Maximum is 50%
        </p> */}
        {/* <div className="form-check form-check-info text-left">
          <input
            id="agree"
            className="form-check-input"
            type="checkbox"
            checked={agree}
            onChange={(e) => setAgree(e.target.checked)}
          />
          <label htmlFor="agree" className="form-check-label">
            Transfer Copyright When Purchased?
          </label>
        </div> */}
        <div className="text-center">
          <button
            disabled={isLoading}
            type="button"
            className="btn bg-black text-white w-100 my-4 mb-2 text-lg"
            onClick={onSubmit}
            style={{ textTransform: 'none' }}
          >
            Create Item
          </button>
        </div>
      </form>
    </div>
  );
};

export default CardForm;
