import AuctionContainer from '../containers/Auction';

const Auction = () => {
  return (
    <>
      <AuctionContainer />
    </>
  );
};

export default Auction;
