import { atom } from 'recoil';

export interface IWalletAccount {
  chainId: 1 | 3 | 4 | 5 | 42 | 56 | 97 | 1001 | 1337 | 8217 | number;
  wallet: string;
  account: string;
}

const walletAccountAtom = atom<IWalletAccount>({
  key: 'walletAccount',
  default: {
    chainId: 1,
    wallet: '',
    account: '',
  },
});

export default walletAccountAtom;
