import contracts from '../constants/contracts';
import IconETH from '../assets/img/icons/icon-eth.png';
import IconBNB from '../assets/img/icons/icon-bnb.png';
import IconKLAY from '../assets/img/icons/icon-klay.png';

function getMainWidth() {
  let SIDBAR: any = document.querySelector(
    '.navbar-vertical.navbar-expand-xs.fixed-left'
  );
  // @ts-ignore
  if (getWidth() > 1200) {
    SIDBAR.style.left = '0px';
  }
}

const handleTitle = (title: any) => {
  document.title = title;
  let SIDBAR: any = document.querySelector(
    '.navbar-vertical.navbar-expand-xs.fixed-left'
  );

  if (document.title === title) {
    SIDBAR.style.left = '-400px';
  } else {
    SIDBAR.style.left = '0px';
  }
};

function getWidth() {
  if (document.documentElement && document.documentElement.clientWidth) {
    return document.documentElement.clientWidth;
  }

  if (document.body) {
    return document.body.clientWidth;
  }
}

const hideShowSidebar = () => {
  let HideShow = false;
  let SIDBAR: any = document.querySelector(
    '.navbar-vertical.navbar-expand-xs.fixed-left'
  );
  // @ts-ignore
  if (getWidth() < 1200) {
    if (HideShow) {
      HideShow = false;
      SIDBAR.style.left = '0px';
    } else {
      HideShow = true;
      SIDBAR.style.left = '220px';
      SIDBAR.style.backgroundColor = '#fff';
    }
  }
};

const closeSidebar = () => {
  let SIDBAR: any = document.querySelector(
    '.navbar-vertical.navbar-expand-xs.fixed-left'
  );

  // @ts-ignore
  if (getWidth() < 1200) {
    SIDBAR.style.left = '0px';
  }
};

const getTokenInfo = async (id: number, nId: number) => {
  try {
    const hash = await contracts(nId).nftContract.methods.tokenURI(id).call();

    console.log('hash', hash);

    const response = await fetch(`https://ipfs.infura.io/ipfs/${hash}?clear`);
    if (!response.ok) {
      throw new Error('Something went wrong');
    }

    const metadata = await response.json();
    const isApproved = await contracts(nId)
      .nftContract.methods.getApproved(id)
      .call();
    const owner = await contracts(nId).nftContract.methods.ownerOf(id).call();
    return {
      id,
      title: metadata.properties.name.description,
      description: metadata.properties.description.description,
      category: metadata.properties.category
        ? metadata.properties.category.description
        : 1,
      img:
        metadata.properties.image.description.indexOf(
          'https://ipfs.infura.io/ipfs/'
        ) !== -1
          ? metadata.properties.image.description
          : `https://ipfs.infura.io/ipfs/${metadata.properties.image.description}`,
      network: metadata.properties.network.description,
      isApproved,
      owner,
    };
  } catch (_) {
    return;
  }
};

const getShortAddress = (address: string) => {
  return `${address.substr(0, 7)}...${address.substr(address.length - 7)}`;
};

const getTokenSymbol = (network: string = 'ETH') => {
  let Icon = IconETH;
  switch (network) {
    case 'BSC':
      Icon = IconBNB;
      break;
    case 'KLAYTN':
      Icon = IconKLAY;
      break;
  }
  return Icon;
};

const getNetworkFromChainId = (chainId: number = 1) => {
  let network = 'ETH';
  switch (chainId) {
    case 56:
    case 97:
      network = 'BSC';
      break;
    case 1001:
    case 8217:
      network = 'KLAYTN';
      break;
  }
  return network;
};

const getTokenPrice = async () => {
  try {
    const data = await fetch(
      new Request(
        `https://api.coingecko.com/api/v3/simple/price?ids=ethereum,binancecoin,klay-token&vs_currencies=usd`
      ),
      {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
        }),
      }
    ).then(async (res) => {
      return await res.json();
    });

    return data;
  } catch (_) {
    return 0;
  }
};

export {
  getMainWidth,
  handleTitle,
  getWidth,
  hideShowSidebar,
  closeSidebar,
  getTokenInfo,
  getShortAddress,
  getTokenSymbol,
  getNetworkFromChainId,
  getTokenPrice,
};
