import React from 'react';
import { useHistory } from 'react-router-dom';
import MyItem from '../../../components/MyItem';
import contracts from '../../../constants/contracts';
import { useRecoilState, useRecoilValue } from 'recoil';
import myItemsAtom from '../../../atoms/myItems';
import myAuctionsAtom from '../../../atoms/myAuctions';
import MyAuctionItem from '../../../components/MyAuctionItem';
import MyBidAuctionItem from '../../../components/MyBidAuctionItem';
import { IAuction } from '../../../types';
import { addresses, networkAddresses } from '../../../constants/addresses';
import { getTokenInfo } from '../../../utils';
import { useMoralisWeb3Api } from 'react-moralis';
import { useInterval } from 'usehooks-ts';
import isAuctionFinishAtom from '../../../atoms/isAuctionFinish';
import { chainStr } from '../../../constants/networkChain';
import myBidAuctionsAtom from '../../../atoms/myBidAuctions';
import walletAccountAtom from '../../../atoms/walletAccount';
import { caverAPI } from '../../../connection/web3';

function SecProjects() {
  const { account: mAccount } = useMoralisWeb3Api();
  const history = useHistory();

  const [ids, setIds] = React.useState<number[]>([]);
  const [balance, setBalance] = React.useState<number>(0);
  const [myAuctions, setMyAuctions] = useRecoilState(myAuctionsAtom);
  const [myItems, setMyItems] = useRecoilState(myItemsAtom);
  const [myBidAuctions, setMyBidAuctions] = useRecoilState(myBidAuctionsAtom);
  const walletAccount = useRecoilValue(walletAccountAtom);

  const [isAuctionFinish, setIsAuctionFinish] =
    useRecoilState(isAuctionFinishAtom);

  const getAuctions = async () => {
    try {
      //y === networkId
      const result = await Promise.all(
        networkAddresses.map(async (x: any, y: number) => {
          const auctions = await contracts(y)
            .nftMarketContract.methods.getUserAuctions(walletAccount.account)
            .call();

          const userAuctions = auctions;
          const promises = userAuctions.map(async (auction: IAuction) => {
            //1: open 2: close(buyNow)
            if (
              auction.auctionTypes.status === '1' ||
              auction.auctionTypes.status === '2'
            ) {
              const tokenInfo = await getTokenInfo(
                parseInt(auction['tokenId']),
                y
              );

              return {
                ...auction,
                tokenInfo,
              };
            }
          });
          return await Promise.all(promises);
        })
      );
      const data: any = result.flat();
      console.log('userAuctions', data);

      setMyAuctions(data.filter((el: any) => el != null));
    } catch (e) {
      console.log(e);
    }
  };

  const getMyBidAuctions = async () => {
    try {
      const result = await Promise.all(
        networkAddresses.map(async (x: any, y: number) => {
          const auctions = await contracts(y)
            .nftMarketContract.methods.getUserBidAuctions(walletAccount.account)
            .call();
          const userBidAuctions = auctions;

          const promises = userBidAuctions.map(async (auction: IAuction) => {
            if (
              auction.auctionTypes.status === '1' ||
              auction.auctionTypes.status === '2'
            ) {
              const tokenInfo = await getTokenInfo(
                parseInt(auction['tokenId']),
                y
              );

              return {
                ...auction,
                tokenInfo,
              };
            }
          });
          return await Promise.all(promises);
        })
      );

      const data: any = result.flat();
      console.log('userBidAuctions', data);

      setMyBidAuctions(data.filter((el: any) => el != null));
    } catch (e) {
      console.log(e);
    }
  };

  const getTotalSupply = async () => {
    const result = await Promise.all(
      networkAddresses.map(async (x: any, y: number) => {
        let tokenList: any[] = [];

        if (walletAccount.wallet === 'klip') {
          if (y === 2) {
            tokenList = await getKlayNFTs();
          }
        } else {
          if (y === 2) {
            tokenList = await getKlayNFTs();
          } else {
            const tokens = await mAccount.getNFTsForContract({
              chain: chainStr[y],
              address: walletAccount.account!,
              token_address: addresses(y).nft,
            });
            console.log('tokens', tokens);

            if (tokens.result) {
              tokenList = tokens.result.map(async (token) => {
                const result = await contracts(y)
                  .nftContract.methods.ownerOf(token.token_id)
                  .call();

                if (result === walletAccount.account) {
                  const tokenInfo = await getTokenInfo(
                    parseInt(token.token_id),
                    y
                  );
                  console.log('tokenInfo', tokenInfo);

                  return tokenInfo;
                }
              });
            }
          }
        }

        return await Promise.all(tokenList);
      })
    );

    console.log('myNft', result.flat());
    setMyItems(result.flat());
  };

  useInterval(
    () => {
      if (walletAccount.account) {
        getTotalSupply();
        getAuctions();
        getMyBidAuctions();
        setIsAuctionFinish(false);
      }
    },
    isAuctionFinish ? 1000 : null
  );

  const getKlayNFTs = async () => {
    const tokens = await caverAPI.kas.tokenHistory.getNFTListByOwner(
      addresses(2).nft,
      walletAccount.account
    );

    let tokenList: any[] = [];

    if (tokens.items) {
      tokenList = tokens.items.map(async (token: any) => {
        const tokenId = parseInt(token.tokenId, 16);
        const result = await contracts(2)
          .nftContract.methods.ownerOf(tokenId)
          .call();

        if (result === walletAccount.account) {
          const tokenInfo = await getTokenInfo(tokenId, 2);

          return tokenInfo;
        }
      });
    }

    return tokenList;
  };

  React.useEffect(() => {
    if (walletAccount.account) {
      getTotalSupply();
      getAuctions();
      getMyBidAuctions();
    } else {
      setMyItems([]);
      history.replace('/');
    }
  }, [walletAccount]);

  return (
    <div className="col-12 mt-4">
      <div className="card mb-4 z-index: -1">
        <div className="card-header pb-0 p-3" id="projects">
          <h6 className="mb-1">My Auctions</h6>
          {/* <p className="text-sm">My NFTs</p> */}
        </div>
        <div className="container-fluid">
          <div className="col-12 py-4">
            <div className="card">
              <div className="card-body p-3">
                <div className="row">
                  {myAuctions.map((item, i) => (
                    <MyAuctionItem key={i} {...item} />
                  ))}

                  {/* <div className="col-md-12 text-center">
                    <NavLink className="btn bg-gradient-dark mb-0 er" to="/">
                      <ITag ClassName="fas fa-plus mr-10" />
                      Explore More
                    </NavLink>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header pb-0 p-3" id="projects">
          <h6 className="mb-1">My NFTs</h6>
          {/* <p className="text-sm">My NFTs</p> */}
        </div>
        <div className="container-fluid">
          <div className="col-12 py-4">
            <div className="card">
              <div className="card-body p-3">
                <div className="row">
                  {myItems.map((item, i) => (
                    <MyItem key={i} {...item} />
                  ))}

                  {/* <div className="col-md-12 text-center">
                    <NavLink className="btn bg-gradient-dark mb-0 er" to="/">
                      <ITag ClassName="fas fa-plus mr-10" />
                      Explore More
                    </NavLink>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header pb-0 p-3" id="projects">
          <h6 className="mb-1">My Bid Auctions</h6>
          {/* <p className="text-sm">My NFTs</p> */}
        </div>
        <div className="container-fluid">
          <div className="col-12 py-4">
            <div className="card">
              <div className="card-body p-3">
                <div className="row">
                  {myBidAuctions.map((item, i) => (
                    <MyBidAuctionItem key={i} {...item} />
                  ))}

                  {/* <div className="col-md-12 text-center">
                    <NavLink className="btn bg-gradient-dark mb-0 er" to="/">
                      <ITag ClassName="fas fa-plus mr-10" />
                      Explore More
                    </NavLink>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecProjects;
