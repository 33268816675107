import FavoritesContainer from '../containers/Favorites'

const Favorites = () => {

  return (
    <>
      <FavoritesContainer />
    </>
  );
}

export default Favorites;

