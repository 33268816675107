import React from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { useInterval } from 'usehooks-ts';
import contracts, { contractWallets } from '../../../constants/contracts';
import swal from 'sweetalert';
import {
  networkId,
  networks,
  symbolChk,
} from '../../../constants/networkChain';
import { ethPrice } from '../../../utils/web3Price';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import walletAccountAtom from '../../../atoms/walletAccount';
import { isMobile } from 'react-device-detect';
import { addresses } from '../../../constants/addresses';
// @ts-ignore
import { prepare, request, getResult } from 'klip-sdk';
import klipRequestKeyAtom from '../../../atoms/klipRequestKey';
import isAuctionFinishAtom from '../../../atoms/isAuctionFinish';

const WithdrawABI = {
  inputs: [],
  name: 'withdrawCredit',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

function SecNavWrapper() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [userPrice, setUserPrice] = React.useState<number>(0);
  const [network, setNetwork] = React.useState<string>('');

  const [klipRequestKey, setKlipRequestKey] =
    useRecoilState(klipRequestKeyAtom);
  const walletAccount = useRecoilValue(walletAccountAtom);
  const setIsAuctionFinish = useSetRecoilState(isAuctionFinishAtom);

  const nId = networkId(network);
  const onChangeDropDown = (event: any, data: any) => {
    setNetwork(data.value);
  };
  const getUserPriceList = React.useCallback(async () => {
    if (walletAccount.account) {
      try {
        const result = await contracts(nId)
          .nftMarketContract.methods.userPriceList(walletAccount.account)
          .call();
        console.log(result);

        setUserPrice(result);
      } catch (e) {
        console.log(e);
      }
    }
  }, [walletAccount.account, nId]);

  useInterval(
    () => {
      getResult(klipRequestKey).then(
        (result: {
          expiration_time: number;
          request_key: string;
          result: { klaytn_address: string };
          klaytn_address: string;
          status: string;
        }) => {
          if (result.status === 'completed') {
            swal(`withdrawal Complete`, `Now try trading more NFTs`, `success`);

            setKlipRequestKey('');
            setIsAuctionFinish(true);
            setIsLoading(false);
          }
        }
      );
    },
    isLoading && klipRequestKey ? 1000 : null
  );

  const withdraw = React.useCallback(async () => {
    if (walletAccount.account) {
      if (walletAccount.wallet === 'klip') {
        setIsLoading(true);
        const res = await prepare.executeContract({
          bappName: 'MINTAINER',
          from: walletAccount.account,
          to: addresses(nId).nftMarket,
          value: '0',
          abi: JSON.stringify(WithdrawABI),
          params: JSON.stringify([]),
        });

        if (res.request_key) {
          if (isMobile) {
            request(res.request_key);
          }
          setKlipRequestKey(res.request_key);
        }
        return;
      }

      try {
        swal('Please wait...', 'Requesting withdrawal', {
          timer: 60000,
        });
        await contractWallets(nId)
          .nftMarketContract.methods.withdrawCredit()
          .send({ from: walletAccount.account });

        swal(`withdrawal Complete`, `Now try trading more NFTs`, `success`);
      } catch (e) {
        console.log(e);
        swal(
          `Transaction request failed`,
          `Please request again or check the network.`,
          `error`
        );
        return;
      }
    }
  }, [walletAccount.account, nId]);

  console.log(network);

  useInterval(
    () => {
      getUserPriceList();
    },
    walletAccount.account ? 2000 : null
  );

  return (
    <div className="col-sm-4 col-8 my-sm-auto ms-auto me-sm-0 mx-auto mt-3">
      <div className="nav-wrapper position-relative end-0">
        <div className="border-0 ps-0 pb-0">
          {/* <strong className="text-dark text-sm">Social:</strong> &nbsp;
          <a
            className="btn btn-facebook btn-simple mb-0 ps-1 pe-2 py-0"
            href="/"
          >
            <ITag ClassName="fab fa-facebook fa-lg" aria-hidden="true" />
          </a>
          <a
            className="btn btn-twitter btn-simple mb-0 ps-1 pe-2 py-0"
            href="/"
          >
            <ITag ClassName="fab fa-twitter fa-lg" aria-hidden="true" />
          </a>
          <a
            className="btn btn-instagram btn-simple mb-0 ps-1 pe-2 py-0"
            href="/"
          >
            <ITag ClassName="fab fa-instagram fa-lg" aria-hidden="true" />
          </a> */}

          <h5 className="mb-1">
            Total Amount : {ethPrice(userPrice.toString())}{' '}
            {userPrice > 0 && (
              <button
                className="btn btn-white bg-light mb-2 w-1"
                onClick={withdraw}
              >
                Withdraw
              </button>
            )}
          </h5>

          {/* {walletAccount.account ? (
            <button
              className="btn btn-white bg-light mb-2 w-1"
              onClick={withdraw}
            >
             
            {new BigNumber(userPrice)
                .div(new BigNumber(10).pow(18))
                .toString()}{' '}
            ETH
            </button>
          ) : null} */}

          {/* {walletAccount.account ? (
           
          ) : null} */}
          <Menu size="mini">
            <Dropdown
              item
              placeholder="Choose Network"
              bold
              direction="right"
              fluid
              selection
              options={networks}
              onChange={onChangeDropDown}
            />
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default SecNavWrapper;
