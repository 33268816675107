import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import Aside from './components/Aside';
import {
  Contact,
  Explore,
  MyCollection,
  Authors,
  CreateItem,
  Home,
  SignIn,
  ConnectWallet,
  Auction,
  ItemDetails,
  SignUp,
  About,
  Favorites,
  Settings,
} from './pages';

import './assets/css/style.css';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useWeb3React } from '@web3-react/core';
import { injectedConnector } from './connector';
import moment from 'moment';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import tokenPriceAtom from './atoms/tokenPrice';
import { getTokenPrice } from './utils';
import { useInterval } from 'usehooks-ts';
import QRCode from 'qrcode.react';
import { isMobile } from 'react-device-detect';
import klipRequestKeyAtom from './atoms/klipRequestKey';
import { ReactComponent as LogoKlip } from './assets/img/icons/logo-klip.svg';
import walletAccountAtom from './atoms/walletAccount';

const App = () => {
  const { chainId, active, account, activate } = useWeb3React();
  const setTokenPrice = useSetRecoilState(tokenPriceAtom);
  const [walletAccount, setWalletAccount] = useRecoilState(walletAccountAtom);
  const [klipRequestKey, setKlipRequestKey] =
    useRecoilState(klipRequestKeyAtom);

  const setToken = async () => {
    const price = await getTokenPrice();

    setTokenPrice((prev) => ({
      ...prev,
      ETH: { rate: price.ethereum.usd, updatedAt: moment() },
      BNB: { rate: price.binancecoin.usd, updatedAt: moment() },
      KLAY: { rate: price['klay-token'].usd, updatedAt: moment() },
    }));
  };

  useEffect(() => {
    if (
      !walletAccount.account &&
      window.localStorage.getItem('wallet') === 'metamask'
    ) {
      activate(injectedConnector);
    }
    // // ethereum
    // if ([1, 3, 4, 5, 42].includes(walletAccount.chainId)) {
    //   activate(injectedConnector);
    // }
    // // bsc
    // if ([56, 97].includes(walletAccount.chainId)) {
    // }
    // // klaytn
    // if ([1001, 8217].includes(walletAccount.chainId)) {
    // }
  }, []);

  useEffect(() => {
    if (active) {
      setWalletAccount({
        chainId: chainId!,
        wallet: 'metamask',
        account: account!,
      });
    }
  }, [active, chainId, account]);

  useEffect(() => {
    window.document.body.classList.add('g-sidenav-show');
    window.document.body.style.backgroundColor = '#f8f9fa !important';
    setToken();
  }, []);

  useInterval(() => {
    setToken();
  }, 1000 * 60 * 5);

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>NFT Marketplace</title>
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
          integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
          crossOrigin="anonymous"
        />
      </Helmet>

      <Aside />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/contact" component={Contact} />
        <Route path="/about" component={About} />
        <Route path="/explore" component={Explore} />
        <Route path="/mycollection" component={MyCollection} />
        <Route path="/authors" component={Authors} />
        <Route path="/createitem" component={CreateItem} />
        <Route path="/signin" component={SignIn} />
        <Route path="/connectwallet" component={ConnectWallet} />
        <Route path="/auction" component={Auction} />
        <Route path="/itemdetails/:id/:network" component={ItemDetails} />
        <Route path="/itemdetails" component={ItemDetails} />
        <Route path="/signup" component={SignUp} />
        <Route path="/favorites" component={Favorites} />
        <Route path="/settings" component={Settings} />
      </Switch>
      {!isMobile && klipRequestKey && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1050,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: 'rgba(0,0,0,0.5)',
            }}
            onClick={() => setKlipRequestKey('')}
          ></div>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              padding: 50,
              backgroundColor: 'white',
              display: 'flex',
            }}
          >
            <div style={{ border: '1px solid #ddd', padding: 20, fontSize: 0 }}>
              <QRCode
                value={`https://klipwallet.com/?target=/a2a?request_key=${klipRequestKey}`}
              />
            </div>
            <div style={{ width: 200, marginLeft: 20 }}>
              <LogoKlip />
              <div style={{ fontSize: 12, marginTop: 10 }}>
                {`QR코드 리더기 혹은 카카오톡 앱 > 더보기 > 우측 상단의 스캔 아이콘을 눌러 QR코드를 스캔해 주세요.`}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
