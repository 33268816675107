import CreateItemContainer from '../containers/CreateItem'

const CreateItem = () => {

  return (
    <>
      <CreateItemContainer/>
    </>
  );
}

export default CreateItem;

