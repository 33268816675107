import { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { closeSidebar } from '../../../utils';
import ITag from '../../ITag';

function SidenavHeader({
  AsidelogoCt,
  hideShowSidebar,
}: {
  AsidelogoCt: any;
  hideShowSidebar: any;
}) {
  const [isComponentVisible, setIsComponentVisible] = useState(AsidelogoCt);
  const ref = useRef(null);

  const handleClickOutside = (event: any) => {
    // @ts-ignore
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
      closeSidebar();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });
  return (
    <div className="sidenav-header">
      <ITag
        ClassName="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute right-0 top-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      />
      <NavLink
        className="d-flex navbar-brand m-0 align-items-center justify-content-between"
        to="/"
      >
        <img src={AsidelogoCt} className="navbar-brand-img" alt="..." />
        {/* <span className="ms-1 font-weight-bold">NFT Marketplace</span> */}
        {/* <ITag       
          onClick={handleClickOutside}
        /> */}
        <div ref={ref}>
          {isComponentVisible && <ITag onClick={handleClickOutside} />}
        </div>
      </NavLink>
    </div>
  );
}

export default SidenavHeader;
