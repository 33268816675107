import moment from 'moment';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

const tokenPriceAtom = atom<{
  ETH: { rate: number; updatedAt: moment.Moment };
  BNB: { rate: number; updatedAt: moment.Moment };
  KLAY: { rate: number; updatedAt: moment.Moment };
}>({
  key: 'tokenPrice',
  default: {
    ETH: { rate: 0, updatedAt: moment() },
    BNB: { rate: 0, updatedAt: moment() },
    KLAY: { rate: 0, updatedAt: moment() },
  },
  effects_UNSTABLE: [persistAtom],
});

export default tokenPriceAtom;
