import { IBid } from '../../../../../types';
import TimeAgo from 'react-timeago';
import BigNumber from 'bignumber.js';
import { ethPrice } from '../../../../../utils/web3Price';
import { symbolChk } from '../../../../../constants/networkChain';
import { getShortAddress } from '../../../../../utils';

function ListTag(props: IBid) {
  return (
    <li className="mb-2">
      <div className="dropdown-item border-radius-md">
        <div className="d-flex py-1">
          {/* <div className="my-auto">
              <img src={img} className="avatar avatar-sm  me-3 " alt="" />
            </div> */}
          <div className="d-flex flex-column justify-content-center">
            <h6 className="text-sm font-weight-normal mb-1">
              <span className="font-weight-bold">By</span>{' '}
              {getShortAddress(props.bidder)}
            </h6>
            <p className="text-xs text-secondary mb-0">
              <i className="fa fa-clock me-1" aria-hidden="true"></i>
              <TimeAgo date={new Date(parseInt(props.timestamp) * 1000)} />
            </p>
          </div>
          <div className="d-flex flex-column justify-content-center ms-auto">
            <h6 className="text-sm font-weight-normal mb-1">
              <span className="text-black font-weight-bold">
                {ethPrice(props.price)} {props.symbol}
              </span>
            </h6>
            {/* <p className="text-xs text-secondary mb-0">${price}</p> */}
          </div>
        </div>
      </div>
    </li>
  );
}

export default ListTag;
