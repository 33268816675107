import ConnectWalletContainer from '../containers/ConnectWallet'

const ConnectWallet = () => {

  return (
    <ConnectWalletContainer />
  );
}

export default ConnectWallet;

