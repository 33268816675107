import {caverAPI, web3, web3Wallet} from "../connection/web3";
import {addresses} from './addresses';
import NFTCollection from '../abis/NFT.json'
import NFTMarketCollection from '../abis/WNFTMarket.json'

const contracts = (id: number) => {
  return {
    // @ts-ignore
    nftContract: id === 2 ? new caverAPI.contract(NFTCollection.abi, addresses(id).nft) : new web3[id].eth.Contract(NFTCollection.abi, addresses(id).nft),
    // @ts-ignore
    nftMarketContract: id === 2 ? new caverAPI.contract(NFTMarketCollection.abi, addresses(id).nftMarket) : new web3[id].eth.Contract(NFTMarketCollection.abi, addresses(id).nftMarket),
  }
};

export const contractWallets = (id: number) => {
  return {
    // @ts-ignore
    nftContract: new web3Wallet.eth.Contract(NFTCollection.abi, addresses(id).nft),
    // @ts-ignore
    nftMarketContract: new web3Wallet.eth.Contract(NFTMarketCollection.abi, addresses(id).nftMarket),
  }
};

export default contracts