import { useEffect } from 'react';
import { handleTitle } from '../../utils';

const SettingsContainer = () => {
  useEffect(() => {
    handleTitle('Settings');
  }, []);

  return (
    <>
      <section className="h-100-vh mb-8">
        {/* <div className="container">
          <div
            className="row mt-lg-n10 mt-md-n11 mt-n10"
            style={{ marginTop: '-10rem' }}
          >
            <div className="col-md-7 mx-auto mb-30">
              <div className="card z-index-0">
                <div className="card-header gray-bg text-center pt-4">
                  <h5>Leave Us a Message</h5>
                </div>
                <CardForm />
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default SettingsContainer;
