import Card from './Card';
import SectionHeading from '../../../components/SectionHeading';
import { Explore2Icon2, Explore2Icon3 } from '../../../utils/allImgs';
import auctionCategoryAtom from '../../../atoms/auctionCategory';
import { useRecoilValue } from 'recoil';
import React from 'react';
import contracts from '../../../constants/contracts';
import data from '../../../data/data-containers/data-Authors';
import { IAuction } from '../../../types';
import { getTokenInfo } from '../../../utils';
import auctionKeywordAtom from '../../../atoms/auctionKeyword';
import auctionSortAtom from '../../../atoms/auctionSort';
import { networkAddresses } from '../../../constants/addresses';
import { networkId } from '../../../constants/networkChain';

const CardNewListed = () => {
  const auctionSort = useRecoilValue(auctionSortAtom);
  const auctionCategory = useRecoilValue(auctionCategoryAtom);
  const auctionKeyword = useRecoilValue(auctionKeywordAtom);
  const [auctions, setAuctions] = React.useState<IAuction[]>([]);

  const getAuctions = async () => {
    const result = await Promise.all(
      networkAddresses.map(async (x: any, y: number) => {
        const auctions = await contracts(y)
          .nftMarketContract.methods.getOpenAuctions(
            auctionCategory,
            auctionSort,
            auctionKeyword,
            0,
            10
          )
          .call();
        const openAuctions = auctions[0];
        const promises = openAuctions.map(async (auction: IAuction) => {
          const tokenInfo = await getTokenInfo(parseInt(auction.tokenId), y);
          return {
            ...auction,
            tokenInfo,
          };
        });
        return await Promise.all(promises);
      })
    );

    const data: any = result.flat();
    console.log('openAuctions', data);
    setAuctions(data);
  };

  React.useEffect(() => {
    getAuctions();
  }, [auctionSort, auctionCategory, auctionKeyword]);

  return (
    <div className="col-lg-8">
      {/* <SectionHeading
        img={Explore2Icon3}
        text="Explore"
        title="Live Auctions"
      /> */}

      {auctions.map((item, i) => (
        <Card key={i} {...item} />
      ))}
    </div>
  );
};

export default CardNewListed;
