import { useEffect } from 'react';
import data from '../../data/data-containers/data-Authors.js';
import Breadcrumb from '../../components/Breadcrumb';
import SectionHeading from '../../components/SectionHeading';
import { AuthorsIcon2 } from '../../utils/allImgs';
import { getMainWidth, handleTitle } from '../../utils';
import Navbar from '../../layouts/Head/Navbar';
import CardBody from './CardBody';
import './AboutHeader.css';

const AboutContainer = () => {
  useEffect(() => {
    handleTitle('About');
    getMainWidth();
  }, []);

  return (
    <>
      <main className="main-content mt-1 border-radius-lg">
        <Navbar />

        <div className="container-fluid">
          <div className="page-header AboutHeaderIMG breadcrumb-header min-height-300 border-radius-xl mt-4 mb-30">
            <Breadcrumb
              text1="MINTAINER NFT Marketplace"
              text2="NFT marketplace where anything is possible and all are welcome"
            />
          </div>
        </div>

        {/* <div className="container-fluid">
          <SectionHeading
            img={AuthorsIcon2}
            text="About"
            title="About"
          />
        </div> */}

        <div className="container-fluid">
          <div className="col-12 py-4">
            <div className="card">{/* <CardBody data={data} /> */}</div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AboutContainer;
