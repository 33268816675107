import * as React from 'react';
import { NavLink } from 'react-router-dom';
import contracts, { contractWallets } from '../../constants/contracts';
import { addresses } from '../../constants/addresses';
import { useWeb3React } from '@web3-react/core';
import { IAuction } from '../../types';
import isAuctionFinishAtom from '../../atoms/isAuctionFinish';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import swal from 'sweetalert';
import {
  networkId,
  symbolChk,
  zeroAddress,
} from '../../constants/networkChain';
import { ethPrice } from '../../utils/web3Price';
import { getNetworkFromChainId, getTokenSymbol } from '../../utils';
import { useInterval } from 'usehooks-ts';
// @ts-ignore
import { prepare, request, getResult } from 'klip-sdk';
import { isMobile } from 'react-device-detect';
import klipRequestKeyAtom from '../../atoms/klipRequestKey';
import walletAccountAtom from '../../atoms/walletAccount';

const CancelAuctionABI = {
  inputs: [
    {
      internalType: 'uint256',
      name: 'auctionId',
      type: 'uint256',
    },
  ],
  name: 'cancelAuction',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

const SettleAuctionABI = {
  inputs: [
    {
      internalType: 'uint256',
      name: 'auctionId',
      type: 'uint256',
    },
  ],
  name: 'settleAuction',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

interface IMyAuctionItemProps extends IAuction {}

const MyAuctionItem: React.FunctionComponent<IMyAuctionItemProps> = (props) => {
  const { account } = useWeb3React();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingCancel, setIsLoadingCancel] = React.useState<boolean>(false);
  const [isLoadingSettle, setIsLoadingSettle] = React.useState<boolean>(false);

  const [klipRequestKey, setKlipRequestKey] =
    useRecoilState(klipRequestKeyAtom);
  const walletAccount = useRecoilValue(walletAccountAtom);
  const setIsAuctionFinish = useSetRecoilState(isAuctionFinishAtom);
  const symbol = symbolChk(props.tokenInfo?.network);
  const nId = networkId(props.tokenInfo?.network);

  console.log('MyAuctionItem', props);

  useInterval(
    () => {
      getResult(klipRequestKey).then(
        (result: {
          expiration_time: number;
          request_key: string;
          result: { klaytn_address: string };
          klaytn_address: string;
          status: string;
        }) => {
          if (result.status === 'completed') {
            if (isLoadingCancel) {
              swal(
                'Cancellation has been completed',
                'Now try trading more NFTs',
                'success'
              );
            } else {
              swal(
                'The settlement has been completed',
                'Now try trading more NFTs',
                'success'
              );
            }

            setKlipRequestKey('');
            setIsAuctionFinish(true);
            setIsLoading(false);
            setIsLoadingCancel(false);
            setIsLoadingSettle(false);
          }
        }
      );
    },
    (isLoading || isLoadingCancel || isLoadingSettle) && klipRequestKey
      ? 1000
      : null
  );

  const onClickCancelAuction = async () => {
    if (
      getNetworkFromChainId(walletAccount.chainId) !== props.tokenInfo?.network
    ) {
      swal('Oops! Please check the network!');
      return;
    }

    if (walletAccount.wallet === 'klip') {
      setIsLoadingCancel(true);
      const res = await prepare.executeContract({
        bappName: 'MINTAINER',
        from: walletAccount.account,
        to: addresses(nId).nftMarket,
        value: '0',
        abi: JSON.stringify(CancelAuctionABI),
        params: JSON.stringify([props.auctionId]),
      });

      if (res.request_key) {
        if (isMobile) {
          request(res.request_key);
        }
        setKlipRequestKey(res.request_key);
      }
      return;
    }

    try {
      swal('Please wait...', 'Auction is being settled', {
        timer: 60000,
      });
      const cancelAuction = await contractWallets(nId)
        .nftMarketContract.methods.cancelAuction(props.auctionId)
        .send({ from: walletAccount.account });
      setIsAuctionFinish(true);
      console.log(cancelAuction);
      swal(
        'Cancellation has been completed',
        'Now try trading more NFTs',
        'success'
      );
    } catch (e) {
      console.log(e);
      swal(
        `Transaction request failed`,
        `Please request again or check the network.`,
        `error`
      );
      return;
    }
  };

  const onClickSettleAuction = async () => {
    if (
      getNetworkFromChainId(walletAccount.chainId) !== props.tokenInfo?.network
    ) {
      swal('Oops! Please check the network!');
      return;
    }

    if (walletAccount.wallet === 'klip') {
      setIsLoadingSettle(true);
      const res = await prepare.executeContract({
        bappName: 'MINTAINER',
        from: walletAccount.account,
        to: addresses(nId).nftMarket,
        value: '0',
        abi: JSON.stringify(SettleAuctionABI),
        params: JSON.stringify([props.auctionId]),
      });

      if (res.request_key) {
        if (isMobile) {
          request(res.request_key);
        }
        setKlipRequestKey(res.request_key);
      }
      return;
    }

    try {
      swal('Please wait...', 'Auction is being settled', {
        timer: 60000,
      });
      const settleAuction = await contractWallets(nId)
        .nftMarketContract.methods.settleAuction(props.auctionId)
        .send({ from: walletAccount.account });
      setIsAuctionFinish(true);
      console.log(settleAuction);
      swal(
        'The settlement has been completed',
        'Now try trading more NFTs',
        'success'
      );
    } catch (e) {
      console.log(e);
      swal(
        `Transaction request failed`,
        `Please request again or check the network.`,
        `error`
      );
      return;
    }
  };

  return (
    <div className="col-xl-3 col-md-6 mb-xl-0">
      <div className="card card-blog card-plain">
        <div
          className="position-relative mb-30 img-fluid shadow border-radius-xl"
          style={{
            backgroundImage: `url('${props.tokenInfo?.img}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minWidth: '100%',
            aspectRatio: '1',
          }}
        ></div>
        <div className="item-cont card-body px-1 pb-0">
          <div className="d-flex justify-content-between">
            <div>
              <p className="text-gradient text-dark mb-2 text-sm">
                #{props.tokenInfo?.id}
              </p>
              <NavLink
                className="text-decoration-none"
                to={`/itemdetails/${props.auctionId}/${props.tokenInfo?.network}`}
              >
                <h5>{props.tokenInfo?.title}</h5>
              </NavLink>
            </div>
            <img
              src={getTokenSymbol(props.tokenInfo?.network)}
              alt=""
              style={{
                width: 30,
                height: 30,
              }}
            />
          </div>
          <p className="mb-0 text-sm">
            Currenct Price :{' '}
            <span className="text-black font-weight-bold">
              {ethPrice(props.currentPrice)} {symbol}
            </span>
          </p>
          <p className="mb-4 text-sm">
            Buy now Price :{' '}
            <span className="text-black font-weight-bold">
              {ethPrice(props.buyNowPrice)} {symbol}
            </span>
          </p>
          <div className="d-flex align-items-center justify-content-between">
            {props.auctionTypes.status === '1' &&
            props.highestBidder === walletAccount.account ? (
              new Date(parseInt(props.expiryDate + '000')) >= new Date() ? (
                <button
                  className="btn btn-outline-dark btn-sm mb-0"
                  onClick={onClickSettleAuction}
                >
                  Settle auction
                </button>
              ) : (
                <div>Bidding</div>
              )
            ) : (
              props.auctionTypes.status === '1' &&
              (new Date(parseInt(props.expiryDate + '000')) <= new Date() ? (
                props.highestBidder === zeroAddress ? (
                  <button
                    className="btn btn-outline-dark btn-sm mb-0"
                    onClick={onClickCancelAuction}
                  >
                    Cancel auction
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-dark btn-sm mb-0"
                    onClick={onClickSettleAuction}
                  >
                    Settle auction
                  </button>
                )
              ) : (
                <button
                  className="btn btn-outline-dark btn-sm mb-0"
                  onClick={onClickCancelAuction}
                >
                  Cancel auction
                </button>
              ))
            )}

            {props.auctionTypes.status === '2' && (
              <button
                className="btn btn-outline-dark btn-sm mb-0"
                onClick={onClickSettleAuction}
              >
                Settle auction
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAuctionItem;
