import MyCollectionContainer from '../containers/MyCollection';

const MyCollection = () => {
  return (
    <>
      <MyCollectionContainer />
    </>
  );
};

export default MyCollection;
