import { NavLink, useHistory } from 'react-router-dom';
import { IAuction } from '../../../types/index';
import { SecLiveAuctionsIconfire } from '../../../utils/allImgs';
import TimeAgo from 'react-timeago';
import { useSetRecoilState } from 'recoil';
import selectedAuctionAtom from '../../../atoms/selectedAuction';
import BigNumber from 'bignumber.js';
import { symbolChk, zeroAddress } from '../../../constants/networkChain';
import { ethPrice } from '../../../utils/web3Price';
import { getTokenSymbol } from '../../../utils';

function LiveAuctionsItem(props: IAuction) {
  const setSelectedAuction = useSetRecoilState(selectedAuctionAtom);
  const history = useHistory();
  const symbol = symbolChk(props.tokenInfo?.network);
  // console.log(props.buyNowPrice)
  return (
    <div className="col-xl-3 col-md-6 mb-xl-0">
      <div
        className="card card-blog card-plain"
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        onClick={async () =>
          history.push(
            `/itemdetails/${props.auctionId}/${props.tokenInfo?.network}`
          )
        }
      >
        <div
          className="position-relative mb-30 img-fluid shadow border-radius-xl"
          style={{
            backgroundImage: `url('${props.tokenInfo?.img}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minWidth: '100%',
            aspectRatio: '1',
          }}
        ></div>
        <div className="auction-timer">
          <img src={SecLiveAuctionsIconfire} width="30" alt="" />
          <p>
            {new Date(parseInt(props.expiryDate + '000')) <= new Date() ? (
              props.highestBidder === zeroAddress ? (
                'No Bids'
              ) : (
                'SOLD OUT'
              )
            ) : (
              <TimeAgo date={new Date(parseInt(props.expiryDate + '000'))} />
            )}
          </p>
        </div>
        {/* <NavLink
          to={path2}
          className="item-owner"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title=""
          data-bs-original-title="view profile"
        >
          <img alt="placeholder" src={img3} />
        </NavLink> */}
        <div className="item-cont card-body px-1 pb-0">
          <div className="d-flex justify-content-between">
            <div>
              <p className="text-gradient text-dark mb-2 text-sm">
                #{props.tokenId}
              </p>
              <div className="text-decoration-none">
                <h5>{props.tokenInfo?.title}</h5>
              </div>
            </div>
            <img
              src={getTokenSymbol(props.tokenInfo?.network)}
              alt=""
              style={{
                width: 30,
                height: 30,
              }}
            />
          </div>
          <p className="mb-0 text-sm" style={{ color: 'black' }}>
            Currenct Price :{' '}
            <span className="text-black font-weight-bold">
              {ethPrice(props.currentPrice)} {symbol}{' '}
            </span>
          </p>
          <p className="text-sm" style={{ color: 'black' }}>
            Buy now Price :{' '}
            <span className="text-black font-weight-bold">
              {ethPrice(props.buyNowPrice)} {symbol}{' '}
            </span>
          </p>
          {/* <div className="d-flex align-items-center justify-content-between">
            <NavLink to="" className="btn btn-outline-primary btn-sm mb-0">
              Buy Now
            </NavLink>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default LiveAuctionsItem;
