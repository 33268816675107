import { useParams } from 'react-router-dom';
import CountDown from 'count-down-react';
import data from '../../../../data/data-components/data-SecNewListed.js';
import ListTag from './ListTag';
import { IAuction } from '../../../../types/index';
import { zeroAddress } from '../../../../constants/networkChain';

function SectionCountDown(auction: IAuction) {
  const { id }: { id?: string } = useParams();
  const item = data[parseInt(id!) - 1];

  const CoundownRenderer = ({ days, hours, minutes, seconds } : any) => (
    <>
       <span className="h2 font-weight-bold">  {days} </span>Days
       <span className="h2 font-weight-bold">  {hours} </span>Hours
       <span className="h2 font-weight-bold">  {minutes} </span>Minutes
       <span className="h2 font-weight-bold">  {seconds} </span>Seconds

    </>
    
  )
  return (
    <div className="col-lg-6">
      <div className="card mb-30">
        <div className="card-body p-3">
          <h6 className="font-weight-bolder mb-0">Auction ends in</h6>
          
          <ul className="dropdown-menu relative dropdown-menu-end text-center show border-0">
          {parseInt(auction.currentPrice) < parseInt(auction.buyNowPrice) 
            ? new Date(parseInt(auction.expiryDate+"000")) <= new Date() && auction.highestBidder === zeroAddress 
            ? <h4>No Bids</h4>
            :<CountDown
            date={new Date(parseInt(auction.expiryDate+"000"))}
            renderer={CoundownRenderer}
            />
            :<h4>SOLD OUT</h4>
            }
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SectionCountDown;
