import { IAuction } from "../types";

 
// const networks = {
//   eth:"eth",
//   ropsten: "ropsten",
//   rinkeby: "rinkeby",
//   goerli: "goerli",
//   kovan: "kovan",
//   polygon: "polygon",
//   mumbai: "mumbai",
//   bsc: "bsc",
//   bscTest: "bsc testnet",
//   avalanche: "avalanche",
//   fantom: "fantom"
// }
  
export const zeroAddress = '0x0000000000000000000000000000000000000000';
export const chainStr: any= ["eth","bsc"];//Moralis
const networkStr = ["ETH","BSC","KLAYTN"]
export const networkId: any= (network: string) => {
    return networkStr.indexOf(network)
};
const symbolStr = ["ETH","BNB","KLAY"];

export const symbolChk: any = (network: string) => {
    return symbolStr[networkId(network)]
};
export const networks = [
    { value: networkStr[0], text: 'ETH'},
    { value: networkStr[1], text: 'BSC'},
    { value: networkStr[2], text: 'KLAYTN'}
];