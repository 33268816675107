import { NavLink, useParams } from 'react-router-dom';
import data from '../../../../data/data-components/data-SecNewListed.js';
import { IAuction } from '../../../../types';
import './SectionPrice.css';
import BigNumber from 'bignumber.js';
import { symbolChk, zeroAddress } from '../../../../constants/networkChain';
import { ethPrice } from '../../../../utils/web3Price';
import { getShortAddress } from '../../../../utils';

function SectionPrice(auction: IAuction) {
  const symbol = symbolChk(auction);

  return (
    <div className="col-lg-6 mt-lg-0">
      <div className="card card-background shadow-none card-background-mask-primary">
        <div className="full-background SectionPriceBG"></div>
        <div className="card-body text-center p-3 w-100">
          {/* <div className="icon icon-shape icon-sm bg-white shadow mx-auto mb-3 d-flex align-items-center justify-content-center border-radius-md">
            <i
              className="ni ni-diamond text-black font-weight-bold text-lg top-0"
              aria-hidden="true"
            ></i>
          </div> */}
          <h5 className="font-weight-bolder text-black up mb-10p">
            Highest Bid
          </h5>
          <p className="text-black">
            The highest bid at the end of the auction will win
          </p>
          <ul className="list-group mt-15p">
            <li className="list-group-item border-0 d-flex align-items-center px-2">
              {/* <NavLink to="/" className="avatar v2 mr-10">
                    <img src={img} alt="kal" width="40" className="border-radius-lg shadow" />
                  </NavLink> */}
              {auction.highestBidder !== zeroAddress ? (
                <>
                  <div className="d-flex align-items-start flex-column justify-content-center">
                    <div className="text-decoration-none">
                      <h6 className="author-name">
                        {getShortAddress(auction.highestBidder)}
                      </h6>
                    </div>
                  </div>
                  <div className="d-flex align-items-start ms-auto flex-column justify-content-center">
                    <h6 className="author-name text-black font-weight-bold mb-0">
                      {ethPrice(auction.currentPrice)} {symbol}
                    </h6>
                    {/* <p className="mb-0 text-dark">${item.highestBid.bidPrice}</p> */}
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-center w-100 fw-bold">
                  There is no bidder.
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SectionPrice;
