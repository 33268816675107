import SignInContainer from '../containers/SignIn'

const SignIn = () => {

  return (
    <>
      <SignInContainer />
    </>
  );
}

export default SignIn;

