import SignUpContainer from '../containers/SignUp'

const SignUp = () => {

  return (
    <>
      <SignUpContainer/>
    </>
  );
}

export default SignUp;

