import AuthorsContainer from '../containers/Authors'


const Authors = () => {

  return (
    <AuthorsContainer />
  );
}

export default Authors;

