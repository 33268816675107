import Web3 from 'web3';
// @ts-ignore
import CaverExtKAS from 'caver-js-ext-kas'
// Web 3 connection
export const web3Wallet = new Web3(Web3.givenProvider);

const ethNetwork = new Web3(new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"))
const bscNetwork = new Web3(new Web3.providers.HttpProvider("https://bsc-dataseed1.ninicoin.io"))
const klayNetwork = new Web3(new Web3.providers.HttpProvider('https://node-api.klaytnapi.com/v1/klaytn'))

export const web3List = [ethNetwork, bscNetwork, klayNetwork];

export const web3 = [ethNetwork, bscNetwork, klayNetwork];

export const caverAPI = new CaverExtKAS(8217, 'KASK3S0OSYFRLCL5EBXIDS7E', 'mrT_pJYHD8lgum9zQAvmq0v_uA0bqYzxYN-5otOO')
