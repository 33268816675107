import { zeroAddress } from './networkChain';
// 0: eth, 1: bsc 2: klaytn
export const networkAddresses = [
  {
    nft: '0x539d694793FDb897D2F7707D7a38473D7e56D060',
    nftMarket: '0x8b5894a101bdaD9b5b4DAD31353b88216F270619'
  },
  {
    nft: '0x18E396B335c8555746AE1fe765F69c30ABf5fE85',
    nftMarket: '0xF4EEC1762840df1C71721304bdD5a8Dd1870d417'
  },
  {
    nft: '0x44b748C4934476dA07BEA3a61561bdCdf849BEce',
    nftMarket: '0xC40e943Bd18F2505b8db8633d8F29081b91fe2cD'
  }
]

export const addresses = (id: number) => {
  return networkAddresses[id]
} 
