import { NavLink } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import contracts, { contractWallets } from '../../../../constants/contracts';
import React from 'react';
import BigNumber from 'bignumber.js';
import { web3 } from '../../../../connection/web3';
import isAuctionFinishAtom from '../../../../atoms/isAuctionFinish';
import { IAuction } from '../../../../types';
import { ethPrice, weiPrice } from '../../../../utils/web3Price';
import swal from 'sweetalert';
import {
  networkId,
  symbolChk,
  zeroAddress,
} from '../../../../constants/networkChain';
import { addresses } from '../../../../constants/addresses';
import { injectedConnector } from '../../../../connector';
// @ts-ignore
import { prepare, request, getResult } from 'klip-sdk';
import klipRequestKeyAtom from '../../../../atoms/klipRequestKey';
import walletAccountAtom from '../../../../atoms/walletAccount';
import { useInterval } from 'usehooks-ts';
import { isMobile } from 'react-device-detect';
import { getNetworkFromChainId } from '../../../../utils';

const PlaceBidABI = {
  inputs: [
    {
      internalType: 'uint256',
      name: 'auctionId',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'bidPrice',
      type: 'uint256',
    },
  ],
  name: 'placeBid',
  outputs: [],
  stateMutability: 'payable',
  type: 'function',
  payable: true,
};

function SectionForm(auction: IAuction) {
  const { account, activate } = useWeb3React();

  const [bidPrice, setBidPrice] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const setIsAuctionFinish = useSetRecoilState(isAuctionFinishAtom);
  const walletAccount = useRecoilValue(walletAccountAtom);
  const [klipRequestKey, setKlipRequestKey] =
    useRecoilState(klipRequestKeyAtom);
  const symbol = symbolChk(auction.tokenInfo?.network);

  const nId = networkId(auction.tokenInfo?.network);

  useInterval(
    () => {
      getResult(klipRequestKey).then(
        (result: {
          expiration_time: number;
          request_key: string;
          result: { klaytn_address: string };
          klaytn_address: string;
          status: string;
        }) => {
          if (result.status === 'completed') {
            setKlipRequestKey('');
            reset();
          }
        }
      );
    },
    isLoading && klipRequestKey ? 1000 : null
  );

  const onPlaceBid = async () => {
    if (
      getNetworkFromChainId(walletAccount.chainId) !==
      auction.tokenInfo?.network
    ) {
      swal('Oops! Please check the network!');
      return;
    }
    // const gas = await web3.eth.getGasPrice();
    console.log(auction.currentPrice, weiPrice(bidPrice));
    console.log(parseInt(ethPrice(auction.currentPrice)));

    if (new Date(parseInt(auction.expiryDate + '000')) <= new Date()) {
      if (auction.highestBidder === zeroAddress) {
        swal('CLOSE', `Now try trading more NFTs`);
      } else {
        swal('SOLD OUT', `Now try trading more NFTs`);
      }
      return;
    }
    if (parseInt(auction.currentPrice) >= parseInt(auction.buyNowPrice)) {
      swal('SOLD OUT', `Now try trading more NFTs`);
      return;
    }
    if (auction.currentPrice >= weiPrice(bidPrice)) {
      swal('Bid', 'It should be higher than the current bid amount');
      return;
    }
    console.log(account);

    setIsLoading(true);

    if (walletAccount.wallet === 'klip') {
      const bidFee = await contracts(nId)
        .nftMarketContract.methods.feePercentage()
        .call();
      const res = await prepare.executeContract({
        bappName: 'MINTAINER',
        from: walletAccount.account,
        to: addresses(nId).nftMarket,
        value: weiPrice(bidPrice + bidPrice * (bidFee / 10000)),
        abi: JSON.stringify(PlaceBidABI),
        params: JSON.stringify([auction.auctionId, weiPrice(bidPrice)]),
      });

      if (res.request_key) {
        if (isMobile) {
          request(res.request_key);
        }
        setKlipRequestKey(res.request_key);
      }
      return;
    }

    try {
      const bidFee = await contracts(nId)
        .nftMarketContract.methods.feePercentage()
        .call();
      console.log(bidFee / 10000);
      console.log(bidPrice);
      console.log(bidPrice * (bidFee / 10000));
      console.log(weiPrice(bidPrice + bidPrice * (bidFee / 10000)));

      swal('Please wait...', 'Bid is being accepted', {
        timer: 60000,
      });
      await contractWallets(nId)
        .nftMarketContract.methods.placeBid(
          auction.auctionId,
          weiPrice(bidPrice)
        )
        .send({
          from: walletAccount.account,
          value: weiPrice(bidPrice + bidPrice * (bidFee / 10000)),
        });

      reset();
    } catch (e) {
      console.log(e);
      swal(
        `Transaction request failed`,
        `Please request again or check the network.`,
        `error`
      );
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const reset = () => {
    setBidPrice(0);
    setIsAuctionFinish(true);
    setIsLoading(false);
    swal(`Bidding Complete`, `Now try trading more NFTs`, `success`);
  };

  return (
    <div className="col-lg-6 mt-s">
      <div className="d-flex flex-column h-100">
        <h5 className="font-weight-bolder">Place a Bid</h5>
        <p>Please present more than the current bid amount</p>
        <form action="post">
          <label>Bid Amount</label>
          <div className="mb-3">
            <input
              type="number"
              className="form-control"
              value={bidPrice}
              onChange={(e) => setBidPrice(parseFloat(e.target.value))}
              placeholder="Your Bid"
            />
          </div>

          <div className="text-center">
            {walletAccount.account ? (
              auction?.seller !== walletAccount.account ? (
                <button
                  type="button"
                  className="btn btn-outline-dark fs-6 fw-bold w-100 mb-0"
                  onClick={onPlaceBid}
                >
                  Place Bid
                </button>
              ) : (
                <div></div>
              )
            ) : (
              // <NavLink
              //   to="/connectwallet"
              //   className="btn bg-black text-white fs-6 fw-bold w-100 mb-0"
              // >
              //   Connect Your Wallet
              // </NavLink>
              <div
                className="btn bg-black text-white fs-6 fw-bold w-100 mb-0"
                onClick={() => {
                  activate(injectedConnector);
                  window.localStorage.setItem('wallet', 'metamask');
                }}
              >
                Connect Your Wallet
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default SectionForm;
