import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import contracts, { contractWallets } from '../../../../constants/contracts';
import { IAuction } from '../../../../types/index';
import { addresses } from '../../../../constants/addresses';
import {
  networkId,
  symbolChk,
  zeroAddress,
} from '../../../../constants/networkChain';
import { getNetworkFromChainId, getShortAddress } from '../../../../utils';
import { ethPrice, weiPrice } from '../../../../utils/web3Price';
import swal from 'sweetalert';
import isAuctionFinishAtom from '../../../../atoms/isAuctionFinish';
import React from 'react';
import klipRequestKeyAtom from '../../../../atoms/klipRequestKey';
// @ts-ignore
import { prepare, request, getResult } from 'klip-sdk';
import walletAccountAtom from '../../../../atoms/walletAccount';
import { isMobile } from 'react-device-detect';
import { useInterval } from 'usehooks-ts';

const PlaceBidABI = {
  inputs: [
    {
      internalType: 'uint256',
      name: 'auctionId',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'bidPrice',
      type: 'uint256',
    },
  ],
  name: 'placeBid',
  outputs: [],
  stateMutability: 'payable',
  type: 'function',
  payable: true,
};

function SectionInfo(auction: IAuction) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const setIsAuctionFinish = useSetRecoilState(isAuctionFinishAtom);
  const walletAccount = useRecoilValue(walletAccountAtom);
  const [klipRequestKey, setKlipRequestKey] =
    useRecoilState(klipRequestKeyAtom);

  const symbol = symbolChk(auction.tokenInfo?.network);
  const network = networkId(auction.tokenInfo?.network);

  useInterval(
    () => {
      getResult(klipRequestKey).then(
        (result: {
          expiration_time: number;
          request_key: string;
          result: { klaytn_address: string };
          klaytn_address: string;
          status: string;
        }) => {
          if (result.status === 'completed') {
            setKlipRequestKey('');
          }
        }
      );
    },
    isLoading && klipRequestKey ? 1000 : null
  );

  const onBuyNow = async () => {
    if (
      getNetworkFromChainId(walletAccount.chainId) !==
      auction.tokenInfo?.network
    ) {
      swal('Oops! Please check the network!');
      return;
    }

    const buyNowPrice = parseInt(auction.buyNowPrice);
    if (new Date(parseInt(auction.expiryDate + '000')) <= new Date()) {
      if (auction.highestBidder === zeroAddress) {
        swal('CLOSE', `Now try trading more NFTs`);
      } else {
        swal('SOLD OUT', `Now try trading more NFTs`);
      }
      return;
    }

    if (parseInt(auction.currentPrice) >= parseInt(auction.buyNowPrice)) {
      swal('SOLD OUT', `Now try trading more NFTs`);
      return;
    }

    setIsLoading(true);

    if (walletAccount.wallet === 'klip') {
      const bidFee = await contracts(network)
        .nftMarketContract.methods.feePercentage()
        .call();

      const res = await prepare.executeContract({
        bappName: 'MINTAINER',
        from: walletAccount.account,
        to: addresses(network).nftMarket,
        value: (buyNowPrice + buyNowPrice * (bidFee / 10000)).toString(),
        abi: JSON.stringify(PlaceBidABI),
        params: JSON.stringify([auction.auctionId, buyNowPrice]),
      });

      if (res.request_key) {
        if (isMobile) {
          request(res.request_key);
        }
        setKlipRequestKey(res.request_key);
      }
      return;
    }

    try {
      const bidFee = await contracts(network)
        .nftMarketContract.methods.feePercentage()
        .call();
      console.log(bidFee);
      console.log(auction.buyNowPrice);
      console.log((buyNowPrice + buyNowPrice * (bidFee / 10000)).toString());

      swal('Please wait...', 'Bid is being accepted', {
        timer: 60000,
      });

      await contractWallets(network)
        .nftMarketContract.methods.placeBid(
          auction.auctionId,
          auction.buyNowPrice
        )
        .send({
          from: walletAccount.account,
          value: (buyNowPrice + buyNowPrice * (bidFee / 10000)).toString(),
        });

      swal(`Bidding Complete`, `Now try trading more NFTs`, `success`);
      setIsAuctionFinish(true);
    } catch (e) {
      console.log(e);
      swal(
        `Transaction request failed`,
        `Please request again or check the network.`,
        `error`
      );
      return;
    }
  };

  return (
    <div className="col-lg-12">
      <div className="d-flex flex-column h-100">
        <p className="mb-1">
          #{auction.tokenId} {auction.tokenInfo?.network}
        </p>
        <h4 className="font-weight-bolder">{auction.tokenInfo?.title}</h4>
        <p className="text-black">{auction.tokenInfo?.description}</p>
        <p className="text-bold mb-0">
          Currenct Price :{' '}
          <span className="text-black font-weight-bold text-lg">
            {ethPrice(auction.currentPrice)} {symbol}
          </span>
        </p>
        <p className="text-bold mb-30">
          Buy now Price :{' '}
          <span className="text-black font-weight-bold text-lg">
            {ethPrice(auction.buyNowPrice)} {symbol}
          </span>
        </p>

        <div className="row">
          <div className="col-md-6">
            <ul className="list-group">
              <li className="list-group-item border-0 ps-0 pt-0 text-sm">
                <strong className="text-dark mr-10">Item Artist: </strong>
                {/* {getShortAddress(auction.seller)} */}
                {auction.seller}
              </li>
              {auction.tokenInfo?.network === 'ETH' && (
                <li className="list-group-item border-0 ps-0 text-sm">
                  <strong className="text-dark mr-10">
                    <a
                      // href={`https://opensea.io/assets/${addresses.nft}/${auction.tokenInfo?.id}`}
                      href={`https://testnets.opensea.io/assets/${
                        addresses(network).nft
                      }/${auction.tokenInfo?.id}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <span>View on OpenSea</span>
                    </a>
                  </strong>
                </li>
              )}
              {/* <li className="list-group-item border-0 ps-0 text-sm">
                <strong className="text-dark">Item Size:</strong>{' '}
                {item.itemSize || ''}
              </li> */}
            </ul>
          </div>

          <div className="col-md-6">
            <ul className="list-group">
              <li className="list-group-item border-0 d-flex align-items-center px-0">
                {/* <NavLink to="/profile" className="avatar v2 me-3">
	                <span className="author-num">1</span>
	                <img src={img} alt="kal" className="border-radius-lg shadow" />
	              </NavLink> */}
                {/* <div className="d-flex align-items-start flex-column justify-content-center">
                  <NavLink to="/">
                    <h6 className="author-name">{item.artist}</h6>
                  </NavLink>
                  <NavLink className="btn btn-link autho-link" to="/">
                    {item.artistId}
                  </NavLink>
                </div> */}
              </li>
              {walletAccount.account &&
                auction.seller !== walletAccount.account && (
                  <li className="list-group-item border-0 d-flex align-items-center px-0">
                    <button
                      type="button"
                      className="btn bg-black text-white fs-6 fw-bold w-100 mb-0"
                      onClick={onBuyNow}
                    >
                      Buy now
                    </button>
                  </li>
                )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionInfo;
