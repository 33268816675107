import { NavLink, useParams } from 'react-router-dom';
import ITag from '../../../../components/ITag';
import data from '../../../../data/data-components/data-SecNewListed.js';
import ListTag from './ListTag';
import { useRecoilValue, useRecoilState } from 'recoil';
import selectedAuctionAtom from '../../../../atoms/selectedAuction';
import React from 'react';
import contracts from '../../../../constants/contracts';
import { IBid, IAuction } from '../../../../types/index';
import { useInterval } from 'usehooks-ts';
import isAuctionFinishAtom from '../../../../atoms/isAuctionFinish';
import { networkId, symbolChk } from '../../../../constants/networkChain';

function SectionBids(auction: IAuction) {
  const { id }: { id?: string } = useParams();
  const item = data[parseInt(id!) - 1];
  const symbol = symbolChk(auction.tokenInfo?.network);
  const nId = networkId(auction.tokenInfo?.network);

  const [bids, setBids] = React.useState<IBid[]>([]);
  const [isAuctionFinish, setIsAuctionFinish] =
    useRecoilState(isAuctionFinishAtom);

  const getBids = async () => {
    const result = await contracts(nId)
      .nftMarketContract.methods.getBids(auction.auctionId)
      .call();

    console.log(result);
    let bids: IBid[] = [];
    result.map((a: any) => {
      bids = [
        ...bids,
        {
          bidId: a['bidId'],
          bidder: a['bidder'],
          price: a['price'],
          timestamp: a['timestamp'],
          symbol: symbol,
        },
      ];
    });
    console.log(bids);
    setBids(bids);
    // setBids(result);
  };

  useInterval(
    () => {
      getBids();
      setIsAuctionFinish(false);
    },
    isAuctionFinish ? 1000 : null
  );

  React.useEffect(() => {
    getBids();
  }, []);

  return bids.length > 0 ? (
    <div className="col-lg-6">
      <div className="card mb-30 rounded-3">
        <div className="card-body p-3">
          <h6 className="font-weight-bolder mb-0">Latest Bids</h6>
          <ul className="scrollable-menu relative   show border-0">
            <ul className="scrolling menu">
              {bids.map((item, i) => (
                <ListTag key={i} {...item} />
              ))}
            </ul>
          </ul>
        </div>
      </div>
    </div>
  ) : null;
}

export default SectionBids;
