import ItemDetailsContainer from '../containers/ItemDetails'

const ItemDetails = () => {

  return (
    <>
      <ItemDetailsContainer />
    </>
  );
}

export default ItemDetails;

