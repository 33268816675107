export const data1 = [
  { text: 'Newest Items', checked: true },
  { text: 'Added This Week', checked: false },
  { text: 'Added This Month', checked: true },
];

export const data2 = [
  { text: 'Crypto Assets', checked: false },
  { text: 'Collectable Items', checked: true },
  { text: 'Gift Cards', checked: false },
];

export const data3 = [
  {
    textArea: false,
    PlaceHolder: 'Item Title',
  },
  {
    textArea: true,
    PlaceHolder: 'Item Description',
  },
  {
    textArea: false,
    PlaceHolder: 'Item Price in ETH',
  },
  {
    textArea: false,
    PlaceHolder: 'Number of Copies',
  },
  {
    textArea: false,
    PlaceHolder: 'Royalities',
  },
  {
    textArea: false,
    PlaceHolder: 'Size',
  },
];
