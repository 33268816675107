import * as React from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import contracts, { contractWallets } from '../../constants/contracts';
import { addresses } from '../../constants/addresses';
import { useWeb3React } from '@web3-react/core';
import Input from '../../containers/CreateItem/CardForm/Input';
import BigNumber from 'bignumber.js';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import isApprovedAtom from '../../atoms/isApproved';
import isAuctionFinishAtom from '../../atoms/isAuctionFinish';
import moment from 'moment';
import { IItem } from '../../types/index';
import { weiPrice } from '../../utils/web3Price';
import {
  networkId,
  symbolChk,
  zeroAddress,
  networks,
} from '../../constants/networkChain';
import swal from 'sweetalert';
import publicIp from 'public-ip';
import { getNetworkFromChainId, getTokenSymbol } from '../../utils';
import walletAccountAtom from '../../atoms/walletAccount';
import klipRequestKeyAtom from '../../atoms/klipRequestKey';
// @ts-ignore
import { prepare, request, getResult } from 'klip-sdk';
import { useInterval } from 'usehooks-ts';
import { isMobile } from 'react-device-detect';
import { caverAPI } from '../../connection/web3';
import NFTMarketCollection from '../../abis/WNFTMarket.json';

const SetApprovalForAllABI = {
  inputs: [
    {
      internalType: 'address',
      name: 'operator',
      type: 'address',
    },
    {
      internalType: 'bool',
      name: 'approved',
      type: 'bool',
    },
  ],
  name: 'setApprovalForAll',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};
const CreateAuctionABI = {
  inputs: [
    {
      internalType: 'address',
      name: '_contractAddress',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: '_tokenId',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_startingPrice',
      type: 'uint256',
    },
    {
      internalType: 'string',
      name: 'auctionTitle',
      type: 'string',
    },
    {
      internalType: 'uint256',
      name: '_buyNowPrice',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'expiryDate',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_category',
      type: 'uint256',
    },
    {
      internalType: 'enum WNFTMarket.TokenType',
      name: '_tokenType',
      type: 'uint8',
    },
    {
      internalType: 'uint256',
      name: '_quantity',
      type: 'uint256',
    },
    {
      internalType: 'string',
      name: 'ip',
      type: 'string',
    },
  ],
  name: 'createAuction',
  outputs: [
    {
      internalType: 'uint256',
      name: 'auctionId',
      type: 'uint256',
    },
  ],
  stateMutability: 'nonpayable',
  type: 'function',
};

interface IMyItemProps extends IItem {}

const MyItem: React.FunctionComponent<IMyItemProps> = (props) => {
  const [isApproved, setIsApproved] = useRecoilState(isApprovedAtom);
  const [isCreateOpen, setIsCreateOpen] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>('');
  const [startingPrice, setStartingPrice] = React.useState<number>();
  const [buyNowPrice, setBuyNowPrice] = React.useState<number>();
  const [expiryDate, setExpiryDate] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  // const symbol = symbolChk(network);
  const nId = networkId(props.network);
  console.log('network:', nId);

  const setIsAuctionFinish = useSetRecoilState(isAuctionFinishAtom);
  const walletAccount = useRecoilValue(walletAccountAtom);
  const [klipRequestKey, setKlipRequestKey] =
    useRecoilState(klipRequestKeyAtom);

  useInterval(
    () => {
      getResult(klipRequestKey).then(
        (result: {
          expiration_time: number;
          request_key: string;
          result: { klaytn_address: string };
          klaytn_address: string;
          status: string;
        }) => {
          if (result.status === 'completed') {
            reset();
            setKlipRequestKey('');
            setIsLoading(false);
          }
        }
      );
    },
    isLoading && klipRequestKey ? 1000 : null
  );

  const createAuctionKlip = async () => {
    // const createAuctionFee = await contractWallets(nId)
    //   .nftMarketContract.methods.createAuctionFee()
    //   .call();

    const uIp = await publicIp.v4();
    if (!uIp) {
      swal('Please check the network status');
      return;
    }

    setIsLoading(true);
    const res = await prepare.executeContract({
      bappName: 'MINTAINER',
      from: walletAccount.account,
      to: addresses(2).nftMarket,
      value: '0',
      abi: JSON.stringify(CreateAuctionABI),
      params: JSON.stringify([
        addresses(2).nft,
        props.id,
        weiPrice(startingPrice!),
        title,
        weiPrice(buyNowPrice!),
        new Date(expiryDate).getTime().toString().substring(0, 10),
        props.category,
        1,
        1,
        uIp,
      ]),
    });

    if (res.request_key) {
      if (isMobile) {
        request(res.request_key);
      }
      setKlipRequestKey(res.request_key);
    }
  };

  const onClickCreateAuction = async () => {
    if (getNetworkFromChainId(walletAccount.chainId) !== props.network) {
      swal('Oops! Please check the network!');
      return;
    }

    if (!isCreateOpen) {
      setIsCreateOpen(true);
      return;
    }

    if (!title) {
      swal('Title is required!');
      return;
    }
    if (!startingPrice) {
      swal('Starting price is required!');
      return;
    }
    if (!buyNowPrice) {
      swal('Buy now price is required!');
      return;
    }
    if (startingPrice >= buyNowPrice) {
      swal('Buy now price have to bigger than Starting price');
      return;
    }
    if (startingPrice < 0.001) {
      swal('Starting price have to bigger than 0.001');
      return;
    }
    if (!expiryDate) {
      swal('Expiry date is required!');
      return;
    }

    if (walletAccount.wallet === 'klip') {
      createAuctionKlip();
    } else {
      createAuction();
    }
  };

  const createAuction = async () => {
    try {
      swal('Please wait...', 'The auction is being registered', {
        timer: 60000,
      });
      const uIp = await publicIp.v4();
      if (!uIp) {
        swal('Please check the network status');
        return;
      }
      /*blockchain timestamp seconds
       * blackList check get public ip
       */
      await contractWallets(nId)
        .nftMarketContract.methods.createAuction(
          addresses(nId).nft,
          props.id,
          weiPrice(startingPrice!),
          title,
          weiPrice(buyNowPrice!),
          new Date(expiryDate).getTime().toString().substring(0, 10),
          props.category,
          1,
          1,
          uIp
        )
        .send({
          from: walletAccount.account,
        });
    } catch (e) {
      console.log(e);
      swal(
        `Transaction request failed`,
        `Please request again or check the network.`,
        `error`
      );
      return;
    }

    reset();
  };

  const getIsApproved = async () => {
    const isApproved = await contracts(nId)
      .nftContract.methods.isApprovedForAll(
        walletAccount.account,
        addresses(nId).nftMarket
      )
      .call();

    setIsApproved(isApproved);
  };

  const setApprovalForAll = async () => {
    if (walletAccount.wallet === 'klip') {
      const res = await prepare.executeContract({
        bappName: 'MINTAINER',
        from: walletAccount.account,
        to: addresses(2).nft,
        value: '0',
        abi: JSON.stringify(SetApprovalForAllABI),
        params: JSON.stringify([addresses(nId).nftMarket, true]),
      });

      if (res.request_key) {
        setIsLoading(true);
        if (isMobile) {
          request(res.request_key);
        }
        setKlipRequestKey(res.request_key);
      }
    } else {
      await contracts(nId)
        .nftContract.methods.setApprovalForAll(addresses(nId).nftMarket, true)
        .send({ from: walletAccount.account });

      setIsApproved(true);
    }
  };

  const reset = () => {
    setIsCreateOpen(false);
    setTitle('');
    setStartingPrice(undefined);
    setBuyNowPrice(undefined);
    setExpiryDate('');
    setIsAuctionFinish(true);

    swal(
      'Your item is now listed for sale',
      'Now try trading more NFTs',
      'success'
    );
  };

  React.useEffect(() => {
    if (!klipRequestKey) {
      setIsLoading(false);
    }
  }, [klipRequestKey]);

  React.useEffect(() => {
    if (walletAccount.account) {
      getIsApproved();
    }
  }, [walletAccount]);

  return (
    <div className="col-xl-3 col-md-6 mb-xl-0">
      <div className="card card-blog card-plain">
        <div
          className="position-relative mb-30 img-fluid shadow border-radius-xl"
          style={{
            backgroundImage: `url('${props.img}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minWidth: '100%',
            aspectRatio: '1',
          }}
        ></div>
        {/* <NavLink to={path2} className="item-owner" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="view profile">
        <img alt="placeholder" src={img2} />
      </NavLink> */}
        <div className="item-cont card-body px-1 pb-0">
          <div className="d-flex justify-content-between">
            <div>
              <p className="text-gradient text-dark mb-2 text-sm">
                #{props.id} {props.network}
              </p>
              <div className="text-decoration-none mb-3">
                <h4>{props.title}</h4>
              </div>
            </div>
            <img
              src={getTokenSymbol(props.network)}
              alt=""
              style={{
                width: 30,
                height: 30,
              }}
            />
          </div>
          {/* <p className="mb-4 text-sm">
            Currenct Price : <span className="text-black font-weight-bold">0 ETH</span>
          </p> */}
          {isCreateOpen && (
            <div className="mb-3">
              <Input
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <Input
                type="number"
                placeholder="Starting price"
                value={startingPrice}
                onChange={(e) => setStartingPrice(parseFloat(e.target.value))}
              />
              <Input
                type="number"
                placeholder="Buy now price"
                value={buyNowPrice}
                onChange={(e) => setBuyNowPrice(parseFloat(e.target.value))}
              />
              <Input
                type="datetime-local"
                placeholder="Expiry date"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
                min={moment().add(1, 'days').toISOString().slice(0, -8)}
                max={moment().add(1, 'years').toISOString().slice(0, -8)}
              />
            </div>
          )}
          <div className="d-flex align-items-center justify-content-between front-weight-bolder inline-flex ">
            {isApproved ? (
              <button
                className="btn btn-outline-dark mb-0 text-lg"
                onClick={onClickCreateAuction}
              >
                Listing Auction
              </button>
            ) : (
              <button
                className="btn btn-outline-dark btn-sm mb-0"
                onClick={setApprovalForAll}
              >
                Approval
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyItem;
