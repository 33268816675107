import { useWeb3React } from '@web3-react/core';
import React from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import walletAccountAtom from '../../../../atoms/walletAccount';
import ITag from '../../../../components/ITag';
import { injectedConnector } from '../../../../connector';
import {
  getNetworkFromChainId,
  getShortAddress,
  getTokenSymbol,
} from '../../../../utils';
import IconMetamask from '../../../../assets/img/icons/icon-metamask.png';
import IconKlip from '../../../../assets/img/icons/icon-klip.png';
import styled from 'styled-components';
// @ts-ignore
import { prepare, request, getResult } from 'klip-sdk';
import { isMobile } from 'react-device-detect';
import { useInterval } from 'usehooks-ts';
import klipRequestKeyAtom from '../../../../atoms/klipRequestKey';

const bappName = 'MINTAINER';

function SecNavItem({ hideShowSidebar }: { hideShowSidebar: any }) {
  const [isShow, setIsShow] = React.useState<boolean>(false);
  const [isKlipLoginLoading, setIsKlipLoginLoading] =
    React.useState<boolean>(false);
  const web3 = useWeb3React();

  const walletAccount = useRecoilValue(walletAccountAtom);
  const setWalletAccount = useSetRecoilState(walletAccountAtom);
  const [klipRequestKey, setKlipRequestKey] =
    useRecoilState(klipRequestKeyAtom);

  const onClickMetamask = () => {
    web3.activate(injectedConnector);
    window.localStorage.setItem('wallet', 'metamask');
    setIsShow(false);
  };

  const onClickKlip = async () => {
    const res = await prepare.auth({ bappName });

    if (res.request_key) {
      if (isMobile) {
        request(res.request_key);
      }
      setIsKlipLoginLoading(true);
      setKlipRequestKey(res.request_key);
    }
  };

  React.useEffect(() => {
    if (web3.active) {
      setWalletAccount({
        chainId: web3.chainId!,
        wallet: 'metamask',
        account: web3.account!,
      });
    }
  }, [web3]);

  useInterval(
    () => {
      getResult(klipRequestKey).then(
        (result: {
          expiration_time: number;
          request_key: string;
          result: { klaytn_address: string };
          klaytn_address: string;
          status: string;
        }) => {
          if (result.status === 'completed') {
            setKlipRequestKey('');
            setWalletAccount({
              chainId: 8217,
              wallet: 'klip',
              account: result.result.klaytn_address,
            });
            setIsKlipLoginLoading(false);
            setIsShow(false);
          }
        }
      );
    },
    isKlipLoginLoading && klipRequestKey ? 1000 : null
  );

  return (
    <>
      <li className="nav-item d-flex align-items-center">
        {walletAccount.account ? (
          <button
            className="btn btn-white bg-light mb-0 w-100 text-lg"
            onClick={() => {
              web3.deactivate();
              window.localStorage.removeItem('wallet');
              setWalletAccount((prev) => ({
                ...prev,
                wallet: '',
                account: '',
              }));
            }}
          >
            <img
              src={getTokenSymbol(getNetworkFromChainId(walletAccount.chainId))}
              alt=""
              style={{ width: 20, height: 20, marginRight: 10 }}
            />
            {getShortAddress(walletAccount.account)}
          </button>
        ) : (
          <button
            className="btn btn-white bg-light mb-0 w-100 text-lg"
            onClick={() => setIsShow(true)}
          >
            Connect Your Wallet
          </button>
        )}
        {/* <NavLink
          to="/signin"
          className="nav-link text-body font-weight-bold px-0"
        >
          <span className="d-sm-inline text-body d-none mr-10">Sign In</span>
        </NavLink> */}
      </li>

      <li className="nav-item d-xl-none px-3 d-flex align-items-center">
        <div className="sidenav-toggler-inner" onClick={hideShowSidebar}>
          {Array(3)
            .fill(0)
            .map((item, i) => (
              <ITag ClassName="sidenav-toggler-line" key={i} />
            ))}
        </div>
      </li>
      {isShow && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1050,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: 'rgba(0,0,0,0.5)',
            }}
            onClick={() => setIsShow(false)}
          ></div>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              padding: 30,
              backgroundColor: 'white',
              display: 'flex',
            }}
          >
            <LoginButtonWrap>
              <LoginButton type="button" onClick={onClickMetamask}>
                <img src={IconMetamask} alt="" /> Connect with MetaMask
              </LoginButton>
              <LoginButton type="button" onClick={onClickKlip}>
                <img src={IconKlip} alt="" /> Connect with Klip
              </LoginButton>
            </LoginButtonWrap>
          </div>
        </div>
      )}
    </>
  );
}

const LoginButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  background-color: white;
  & > img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  &:nth-child(n + 2) {
    margin-top: 10px;
  }
`;

export default SecNavItem;
