import {ConnectWalleWhiteCurved} from '../../utils/allImgs'

const data = [
      {
        imgBack:ConnectWalleWhiteCurved,
        text1:'Meta Mask',
      },
      {
        imgBack:ConnectWalleWhiteCurved,
        text1:'WalletConnect',
      },
      {
        imgBack:ConnectWalleWhiteCurved,
        text1:'Ledger Wallet',
      },
      {
        imgBack:ConnectWalleWhiteCurved,
        text1:'Trezor Wallet',
      }
]

export default data