import SettingsContainer from '../containers/Settings'

const Settings = () => {

  return (
    <>
      <SettingsContainer />
    </>
  );
}

export default Settings;

