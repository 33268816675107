import ExploreContainer from '../containers/Explore'

const Explore = () => {

  return (
    <>
      <ExploreContainer />
    </>
  );
}

export default Explore;