import { NavLink } from 'react-router-dom';
import LiveAuctionsItem from './LiveAuctionsItem';
import ITag from '../ITag';
import data from '../../data/data-components/data-SecLiveAuctions.js';
import React from 'react';
import contracts from '../../constants/contracts';
import { IAuction } from '../../types/index';
import { getTokenInfo } from '../../utils';
import { networkAddresses } from '../../constants/addresses';
import { networkId } from '../../constants/networkChain';
let limit = 10;

function SecLiveAuctions() {
  const [auctions, setAuctions] = React.useState<IAuction[]>([]);
  const onClickMore = () => {
    limit = limit + 10;
    getAuctions();
  };
  const getAuctions = async () => {
    const result = await Promise.all(
      networkAddresses.map(async (x: any, y: number) => {
        const auctions = await contracts(y)
          .nftMarketContract.methods.getOpenAuctions(0, 1, '', 0, limit)
          .call();
        const openAuctions = auctions[0];
        const promises = openAuctions.map(async (auction: IAuction) => {
          const tokenInfo = await getTokenInfo(parseInt(auction.tokenId), y);
          return {
            ...auction,
            tokenInfo,
          };
        });
        return await Promise.all(promises);
      })
    );

    const data: any = result.flat();
    console.log('openAuctions', data);
    setAuctions(data);
  };

  React.useEffect(() => {
    getAuctions();
  }, []);

  return (
    <div className="container-fluid">
      <div className="col-12 py-4">
        <div className="card">
          <div className="card-body">
            <div className="row">
              {auctions.map((item, i) => (
                <LiveAuctionsItem key={i} {...item} />
              ))}

              <div className="col-md-12 text-center">
                <NavLink
                  className="btn bg-gradient-dark mb-0 more"
                  onClick={onClickMore}
                  to="/"
                >
                  <ITag ClassName="fas fa-circle mr-10" />
                  Explore More
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecLiveAuctions;
